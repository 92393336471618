import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    MenuItem,
    useMediaQuery,
    useTheme,
    Container,
    TextField,
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    keyframes,
} from "@mui/material";
import axiosInstance from "../utils/AxiosInstance";
import BookView from "../bookViewer/BookView";
import GuinnessBookView from "./GuinnessBookView";
import BookAnimation from "./BaloonAnimation";
import pubLogo from "../../assets/Tamilezhuthapadi Publications.png"
import guinnessLogo from "../../assets/Guinness.png"
import bgOne from "../../assets/gui-bg.svg"
import bgTwo from "../../assets/gui-bg-two.svg"
import { Link } from "react-router-dom";



const GuinnessBooks = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [bookCounts, setBookCounts] = useState({
        totalBooks: 0,
        remainingBooks: 0,
    });
    const [time, setTime] = useState("08:30 AM");
    const [isHovered, setIsHovered] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pdfPages, setPdfPages] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState("G1");
    const [books, setBooks] = useState([]);
    const [readDialogOpen, setReadDialogOpen] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    

    // console.log(selectedBook, "my");
    
    const fetchBookCounts = async () => {
        try {
            const { data: totalBooks } = await axiosInstance.get(`/guinness/get-guinness-books-count`);
            const { data: remainingBooks } = await axiosInstance.get(`/guinness/get-remaining-books-count`);
            // console.log('remainingBooks', totalBooks, remainingBooks)
            setBookCounts({
                totalBooks: totalBooks.total_books,
                remainingBooks: remainingBooks.remaining_books,
            });

            // console.log(totalBooks, "Total books count fetched successfully");
            // console.log(remainingBooks, "Remaining books count fetched successfully");
        } catch (error) {
            // console.error("Error fetching book counts:", error);
            setBookCounts({
                totalBooks: "Error",
                remainingBooks: "Error",
            });
        }
    };

    useEffect(() => {
        fetchBookCounts();
    }, []);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const { data } = await axiosInstance.get(
                    `/guinness/list-guinness-books?group=${selectedBatch}`
                );
                // console.log("Response:", data);
                setBooks(data);
            } catch (error) {
                // console.error("Error fetching data:", error);
            }
        };
        if (selectedBatch) {
            fetchBooks();
        }
    }, [selectedBatch])

    const handleBatchChange = async (event) => {
        const value = event.target.value;
        setSelectedBatch(value);


    };

    useEffect(() => {
        const initialTime = new Date();
        initialTime.setHours(8, 30, 0);

        const timerInterval = setInterval(() => {
            initialTime.setSeconds(initialTime.getSeconds() + 1);
            const hours = initialTime.getHours();
            const minutes = initialTime.getMinutes();
            const seconds = initialTime.getSeconds();
            const ampm = hours >= 12 ? "PM" : "AM";

            const formattedTime = `${String(hours % 12 || 12).padStart(2, "0")}:${String(
                minutes
            ).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${ampm}`;

            setTime(formattedTime);
        }, 1000);

        return () => clearInterval(timerInterval);
    }, []);

    const onLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPdfPages(new Array(numPages).fill(null).map((_, index) => index + 1));
    };

    const handleReadClick = (book) => {
        if (book) {
        setSelectedBook(book);
        setReadDialogOpen(true);
        }
    };

    const handleCloseReaderDialog = () => {
        setReadDialogOpen(false); 
        setShowAnimation(true); 
        fetchBookCounts();
                
        setTimeout(() => {
            setShowAnimation(false);
        }, 5000);

        
    };

  const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly enlarge */
  }
  100% {
    transform: scale(1);
  }
`;

    return (
        <Box
            sx={{
                // background: "linear-gradient(to bottom, #ffb74d, #f57c00)",
                minHeight: "100vh",
                padding: "30px",                                
            }}
        >
            <Container>
                <Box 
                    sx={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center" 

                    }}
                >
                    <Box
                        component="img"
                        position="absolute"
                        display={{xs:"none", md:"block"}}
                        top={{ xs: "10%", sm: "5%", md: "1%",lg:'-1%' }} 
                        left={{ xs: "60%", sm: "70%", md: "75%", lg:"75%"}}
                        src={bgTwo}
                        sx={{
                            width: { xs: "80%", sm: "20%", md: "200px" },
                            height: "auto",         
                        }}
                    />                        
                    <Box
                        component="img"
                        position="absolute"
                        display={{xs:"none",md:"block"}}
                        top={{ xs: "10%", sm: "5%", md: "14%",lg:'14%' }} 
                        right={{ xs: "60%", sm: "70%", md: "73%", lg:"73%" }}
                        src={bgOne}
                        sx={{
                            width: { xs: "80%", sm: "20%", md: "200px" },
                            height: "auto",         
                        }}
                    />   
                    <Box display="flex">                                                
                        <Box component="img" src={pubLogo} width={150} height={180} pr={5}/>
                        <Box component="img" src={guinnessLogo} width={150} height={200} />                              
                    </Box>
                    <Typography
                        variant="h4"
                        textAlign="center"
                        color="text.primary"
                        fontFamily="fantasy"                         
                        sx={{ mt: 2, textTransform:'uppercase' }}
                    >
                        GUINNESS SPECIAL EDITIONS - READ-A-THON
                    </Typography>
                </Box>
                <Grid container spacing={2} alignItems="center" pt={5} justifyContent={isMobile ? "center" : "space-between"}
                    px={10}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h5" fontFamily="fantasy">Total Books: {bookCounts.totalBooks}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h5" fontFamily="fantasy" textAlign={isMobile ? "center" : "right"}>
                            Remaining: {bookCounts.remainingBooks}
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} alignItems="center" sx={{ mt: 5 }} > */}
                    <Grid item xs={12} sm={10}>
                        <Box display='flex' alignItems="center" justifyContent="center">
                        <TextField
                            select
                            label="Group"
                            value={selectedBatch}
                            onChange={handleBatchChange}
                            sx={{
                            ml: 4,
                            minWidth: 150,
                            height: 40,
                            "& .MuiInputLabel-root": {
                                color: "#000000", 
                                fontSize: "1rem",
                                fontFamily: "Comic Sans MS", 
                                "&.Mui-focused": {
                                color: "#000000", 
                            },
                            },
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "12px",
                                height: "40px", 
                                "& fieldset": {
                                borderColor: "#000000", 
                                },
                                "&:hover fieldset": {
                                borderColor: "#000000", 
                                animation: `${bounce} 0.5s ease-in-out`,
                                },
                                "&.Mui-focused fieldset": {
                                borderColor: "#000000", 
                                },
                            },
                            "& .MuiSelect-select": {
                                color: "#030303",
                                fontSize: "1rem",
                                padding: "8px 14px",
                                fontFamily: "Comic Sans MS",
                            },
                            }}
                        >
                        <MenuItem value="G1">Group 1</MenuItem>
                        <MenuItem value="G2">Group 2</MenuItem>
                        <MenuItem value="G3">Group 3</MenuItem>
                        <MenuItem value="G4">Group 4</MenuItem>
                        <MenuItem value="G5">Group 5</MenuItem>
                        <MenuItem value="G6">Group 6</MenuItem>
                        <MenuItem value="G7">Group 7</MenuItem>
                        <MenuItem value="G8">Group 8</MenuItem>
                        <MenuItem value="G9">Group 9</MenuItem>
                        <MenuItem value="G10">Group 10</MenuItem>
                    </TextField>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                textAlign: "center",
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                background: "#ffffff",
                                borderRadius: "8px",
                                padding: "10px",
                                width: isMobile ? "100%" : "150px",
                                margin: "0 auto",
                            }}
                        >
                            {time}
                        </Box>
                    </Grid> */}
                {/* </Grid> */}
                <Grid container spacing={5} sx={{ mt: 5 }}>
                    {books.map((book) => (
                        book && (
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            key={book.id}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    boxShadow: 3,
                                    borderRadius: "12px",
                                    textAlign: "center",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "300px",
                                    width: "230px",
                                    "&:hover .overlay": {
                                        opacity: 0.7,
                                      },
                                }}
                                onMouseEnter={() => setIsHovered(book.pdf_url)}
                                onMouseLeave={() => setIsHovered(null)}
                            >
                                <Box
                                    component="img"
                                    src={`${process.env.REACT_APP_GUIN_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
                                    alt={book.title}
                                    sx={{
                                        width: "100%",
                                        height: "300px",
                                        objectFit: "cover",
                                    }}
                                />

                                <Box
                                        className="overlay"
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor: "rgba(0, 0, 0, 0.6)", 
                                            opacity: 0,
                                            transition: "opacity 0.3s ease", 
                                        }}
                                        />

                                {isHovered === book.pdf_url && (
                                    <Button
                                        variant="outlined"
                                        
                                        sx={{
                                            width:100,
                                            height:60,
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            borderRadius:0,
                                            transform: "translate(-50%, -50%)",                                            
                                            color: "white",              
                                            bgcolor:'#0000006d',     
                                            borderColor:"#ffffff",                                                      
                                            "&:hover": {
                                                backgroundColor: "#000000a3",
                                                borderColor:"#ffffff",           
                                            },
                                        }}
                                        onClick={() => handleReadClick(book)}
                                    >
                                        Read
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        )
                    ))}
                </Grid>
            </Container>            

            <GuinnessBookView
                readDialogOpen={readDialogOpen}
                onCloseReadDialog={handleCloseReaderDialog}
                book={selectedBook}                
            />

        {/* {showAnimation && <BookAnimation isVisible={showAnimation} />} */}
        <Typography align='center' pt={5}>Powered by
                    <Box component={Link} to="https://www.keelis.com" target='_label'
                      sx={{
                        textDecoration: "none", color: 'blue',
                        display: 'inline'
    
                      }}
                    > keelis</Box>
                  </Typography>
        </Box >
    );
};

export default GuinnessBooks;