import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fagamepad } from '@fortawesome/free-solid-svg-icons';
import GameIcon from "@mui/icons-material/SportsEsports";
import { keyframes } from "@emotion/react";
import links from "../../data/links";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import logonew from "../../assets/logonew.png";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "../utils/AxiosInstance";
import CloseIcon from "@mui/icons-material/Close";
// import ControlPanel from "./ControlPanel";
import { useNavigate } from "react-router-dom";
import BookAnimation from "./BaloonAnimation";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const blink = keyframes`
  0%, 100% {
    opacity: 1;
    color: black;
  }
  50% {
    opacity: 1;
    color: red;
  }
`;

const PageCover = React.forwardRef((props, ref) => {
  return (
    <Box ref={ref} data-density="hard">
      <Box
        bgcolor="#ffffff"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">{props.children}</Typography>
      </Box>
    </Box>
  );
});

const PdfPage = React.forwardRef(({ pageNumber, scale, style }, ref) => {
  return (
    <Box className="page" ref={ref} data-density="soft">
      <Page
        style={{ boxSizing: "border-box" }}
        canvasBackground="#ffffff"
        pageNumber={pageNumber}
        renderTextLayer={true}
        renderAnnotationLayer={false}
        customTextRenderer={({ str, itemIndex }) => str}
        scale={scale}
      />
    </Box>
  );
});

const GuinnessBookView = ({ readDialogOpen, onCloseReadDialog, book, userId }) => {
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);
  const [zoom, setZoom] = useState(1.0);
  // const [page, setCurrPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [orientation, setOrientation] = useState("landscape");
  const [state, setState] = useState("read");
  const flipBook = useRef(null);
  const { t } = useTranslation("common");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gameDialogopen, setGameDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasRecordedView, setHasRecordedView] = useState(false);
  const navigate = useNavigate();


  //console.log(numPages, page, "ee");


  const handleContinueReadingClick = () => {
    navigate("/books");
  };

  const handleNext = () => {
    setPage((prevPage) => Math.min(prevPage + 1, numPages));
    flipBook.current.pageFlip().flipNext();
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    flipBook.current.pageFlip().flipPrev();
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.05, 1.077));
  };
  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.05, 0.5));
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTotalPage(numPages);
  }
  // const location = useLocation();
  // const { book, userId  } = location.state || {};
  //console.log("my",book, userId);

  const recordView = async () => {
    try {
      await axiosInstance.put(`guinness/book/${book.id}/read`);
      // console.log("View recorded successfully");
    } catch (error) {
      // console.error("Error recording view:", error);
    }
  };

  const handleClick = () => {
    const nextIndex = (currentIndex + 3) % links.length;
    setCurrentIndex(nextIndex);
    window.open(links[nextIndex], "_blank", "noopener,noreferrer");
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setGameDialogOpen(false);  // This will close the game dialog
    onCloseReadDialog();     
    recordView();  // Call the passed onCloseReadDialog function
  };

  useEffect(() => {
    if (flipBook.current) {
      const pageFlip = flipBook.current.pageFlip();
      if (pageFlip) {
        setTotalPage(pageFlip.getPageCount());
      }
    }
  }, [flipBook]);

  const onFlip = (e) => {
    const currentPage = e.data + 1; // e.data is zero-indexed
    setCurrentPage(currentPage);
    //console.log("curr p", currentPage);

    const percentageRead = (currentPage / numPages) * 100;
    //console.log("%", percentageRead);

    // Record view if percentage read is above 70% and view hasn't been recorded yet
    if (percentageRead >= 70 && !hasRecordedView) {
      recordView();
      setHasRecordedView(true);
    }

    if (currentPage === numPages || currentPage + 1 === numPages) {
      setGameDialogOpen(true);
    }
  };

  const onChangeOrientation = (e) => {
    setOrientation(e.data);
  };

  const onChangeState = (e) => {
    setState(e.data);
  };

  if (!book) return null;
  return (
    <Dialog
      open={readDialogOpen}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          width: "100vw",
          height: "100vh",
          margin: 0,
          maxWidth: "none",
          maxHeight: "none",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* Add your dialog title content here */}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          gap: 5,
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#292828",
          overflow: "hidden",
        }}
      >
        <HTMLFlipBook
          width={700 * zoom}
          height={500 * zoom}
          onFlip={onFlip}
          ref={flipBook}
          style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.299)",
          }}
        >
          {[...Array(numPages).keys()].map((pNum) => (
            <Box
              key={pNum}
              sx={{
                width: `${700 / zoom}px`, // Inversely proportional to zoom
                height: `${500 / zoom}px`,
                position: "fixed",
                backgroundColor: "#fff",
                margin: "auto",
                overflow: "hidden",
              }}
            >
              <Document
                file={`${process.env.REACT_APP_GUIN_PDF_BASE_URL}${book.pdf_url}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pNum + 1}
                  width={700 * zoom}
                  height={500 * zoom}
                  scale={zoom}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
              <Typography variant="body1" color="white" textAlign="center">
                Page {pNum + 1} of {numPages}
              </Typography>
            </Box>
          ))}
        </HTMLFlipBook>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          mt={2}
          sx={{
            position: "relative", // Keep the Stack in its place
            zIndex: 10, // Ensure it stays on top of the flipbook
          }}
        >
          <IconButton
            onClick={handleZoomIn}
            sx={{
              color: "white",
              opacity: zoom >= 1.077 ? 0.5 : 1,
              pointerEvents: zoom >= 1.077 ? "none" : "auto",
            }}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            onClick={handlePrevious}
            // disabled={page <= 1}
            sx={{
              color: 'white'
              // color: page <= 1 ? "red" : "white",
              // "&.Mui-disabled": {
              //   color: "grey",
              // },
              // "&:hover": {
              //   color: page <= 1 ? "darkred" : "lightblue",
              // },
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            // disabled={page >= numPages}
            sx={{
              color: 'white'
              // color: page >= numPages ? "grey" : "white",
              // "&.Mui-disabled": {
              //   color: "grey",
              // },

              // "&:hover": {
              //   color: page >= numPages ? "darkgrey" : "lightblue",
              // },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <IconButton
            onClick={handleZoomOut}
            sx={{
              color: "white",
              opacity: zoom <= 0.5 ? 0.5 : 1,
              pointerEvents: zoom <= 0.5 ? "none" : "auto",
            }}
          >
            <ZoomOutIcon />
          </IconButton>
        </Stack>

        {/* <Dialog
          open={gameDialogopen}
          onClose={handleClose}
          maxWidth={false}
          PaperProps={{
            sx: {
              width: "600px",
              height: "400px",
              border: "1px",
              maxWidth: "none",
              borderRadius: "10px",
              backgroundColor: "#cee6f0",
            },
          }}
        >

          <Grid>
            <Box sx={{
              display: 'flex',
              justifyContent: 'end',
            }}>
              <IconButton onClick={handleClose} disableRipple>
                <CloseIcon
                  sx={{

                    color: "red",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',



            }}>
              <img
                src={logonew}
                alt="Logo"
                // sx={{ width: "20px", height: "20px" }}

                style={{ width: "100px", height: "100px", paddingBottom: '10px' }}
              />

            </Box>
            <Box sx={{
              alignItems: 'center'
            }}>
              <Typography
                sx={{
                  fontSize: "27px",
                  fontWeight: "bold",
                  color: "green",
                  display: 'flex',
                  justifyContent: 'center',
                  top: 10
                }}
              >
                Thanks for Reading
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "black",
                  position: "relative",
                  animation: `${blink} 2s infinite`,
                }}
              >Wanna play a game..?</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>


                <IconButton
                  disableRipple
                  sx={{
                    width: "90px",
                    height: "95px",
                    top: 10,

                  }}
                  onClick={handleClick}
                >
                  <GameIcon sx={{ width: "115px", height: "135px" }} />
                </IconButton>

              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Typography sx={{ top: 10 }}>(OR)</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Button
                  onClick={handleContinueReadingClick}
                  sx={{
                    top: 10,
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  Continue Reading....
                </Button>
              </Box>
            </Box>

          </Grid>
        </Dialog> */}
      </Box>

      {gameDialogopen && <BookAnimation isVisible={gameDialogopen} />}

    </Dialog>
  );
};

export default GuinnessBookView;
