import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion';

const BookAnimation = ({ isVisible }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [showBalloons, setShowBalloons] = useState(false);

  useEffect(() => {
    if (isVisible) {      
      setShowConfetti(true);
      setShowBalloons(true);
      
      setTimeout(() => {
        setShowConfetti(false);
        setShowBalloons(false);
      }, 5000); 
    } else {
      setShowConfetti(false);
      setShowBalloons(false);
    }
  }, [isVisible]); 

  return (
    <div 
    style={{ 
      textAlign: 'center',       
      width: '100vw', 
      
      overflow: 'hidden'
    }}
  >      
      {showConfetti && <Confetti />}

      {showBalloons && (
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: -1000, opacity: 1 }}
          transition={{ duration: 5, ease: 'easeOut' }}
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '50px',
            height: '70px',
            background: 'linear-gradient(45deg, #ff00e6, #0096f9)',
            borderRadius: '50%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        />
      )}
      
      {showBalloons && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1.5, rotate: 360 }}
          transition={{ duration: 2, repeat: 3, repeatType: 'reverse' }}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '30px',
            height: '30px',
            background: '#00bbff',
            clipPath:
              'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
          }}
        />
      )}
    </div>
  );
};

export default BookAnimation;
