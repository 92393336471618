const links = [
  "https://create.kahoot.it/share/1-1-kahhot-game/9c4ec39b-a714-419d-86a1-aeba6a1c117f",
  "https://create.kahoot.it/share/tamil-family/64ee0630-eb03-4439-99d7-c017da3fb7d1",
  "https://create.kahoot.it/details/1b54bb7c-c29d-4af7-9415-d8fba831d231",
  "https://create.kahoot.it/share/mazhalai-section-d/c0efc5ea-f888-4250-9196-281bd8a7f377",
  "https://create.kahoot.it/share/mazhalai-section-d/094df26e-205b-429d-8fec-861a10aa1183",
  "https://create.kahoot.it/share/mazhalai-section-d-body-parts-in-tamil/7a5841a7-4573-4b01-ba83-96d58e33e95b",
  "https://create.kahoot.it/details/b5debd87-0178-40c4-937c-6a8c96a42688",
  "https://dashboard.blooket.com/set/64dfe633ff6f69f30263279e",
  "https://dashboard.blooket.com/set/64dfe0df63d5690d28fbb35d",
  "https://create.kahoot.it/creator/07cc6725-fb34-4e71-85b4-bc077299d03c",
  "https://create.kahoot.it/share/tamil-phrases-nataniya-d/07ab3bfe-a2ee-4abc-ae8c-ff480e04c124",
  "https://dashboard.blooket.com/set/64dff4baa88a121ebbf44b73",
  "https://dashboard.blooket.com/set/64dffba1ff6f69f302632ede",
  "https://dashboard.blooket.com/set/64dffb29ff6f69f302632ebc",
  "https://create.kahoot.it/share/tamil-vowels-and-consonants/d5134b9e-0c79-46a9-8aeb-89c6b315e4de",
  "https://dashboard.blooket.com/set/64e92002bbd9076b817b8e20",
  "https://dashboard.blooket.com/set/64e92625bbd9076b817b916a",
  "https://create.kahoot.it/share/nillai-1-chapter-3-kahoot/ff9e8a5a-12b1-4f4a-852e-264ce0795dbb",
  "https://dashboard.blooket.com/set/64e930ba98ebc0f6aa18c69b",
  "https://dashboard.blooket.com/set/64e93362bbd9076b817b97c2",
  "https://dashboard.blooket.com/set/64e9357cbbd9076b817b98a4",
  "https://dashboard.blooket.com/set/64e93698bbd9076b817b9928",
  "https://dashboard.blooket.com/set/64f119d2f3b8ce04caf3a731",
  "https://dashboard.blooket.com/set/64f11d0365256a9a7b9e0892",
  "https://dashboard.blooket.com/set/64f1153865256a9a7b9e02e6",
  "https://dashboard.blooket.com/set/64f116a0f3b8ce04caf3a4eb",
  "https://dashboard.blooket.com/set/64f11732f3b8ce04caf3a559",
  "https://create.kahoot.it/share/nillai-1-chapter-3-kahoot/ff9e8a5a-12b1-4f4a-852e-264ce0795dbb",
  "https://create.kahoot.it/share/learn-a-language/8923b8d3-19a7-4c47-b7f7-3063a4a01ca0",
  "https://create.kahoot.it/share/chapter-5-nillai-1c-kahoot/70be8915-ee15-40a9-80a2-e097e03209c8",
  "https://dashboard.blooket.com/set/656a7a543851d7fe89491f59",
  "https://create.kahoot.it/share/nillai-1c-chapter-6-kahoot/79587db2-e62f-43fc-856d-08f569fa8306",
  "https://docs.google.com/document/d/17QzXQCh0Hz_XPUiTy9opogW2KuzeWmwy9qYJ2x-E0Xs/edit",
  "https://dashboard.blooket.com/set/6504d27ffe2b2187e9d3df08",
  "https://dashboard.blooket.com/set/6504d482ca481ffe13daa973",
  "https://dashboard.blooket.com/set/6504d12bfe2b2187e9d3de20",
  "https://dashboard.blooket.com/set/6504d011fe2b2187e9d3dd64",
  "https://dashboard.blooket.com/set/6504e7e40f6d9e2dbaaaff30",
  "https://dashboard.blooket.com/set/6504e3aefe2b2187e9d3e9b4",
  "https://dashboard.blooket.com/set/64e92002bbd9076b817b8e20",
  "https://dashboard.blooket.com/set/64dfe421ff6f69f302632678",
  "https://dashboard.blooket.com/set/64e92625bbd9076b817b916a",
  "https://create.kahoot.it/share/nillai-1c-chapter-7-quiz/a27779a7-4392-4df8-a836-c5e90002acac",
  "https://dashboard.blooket.com/set/650e2528f6673a1d8b96172e",
  "https://create.kahoot.it/share/chapter-8-kahoot/e2a30edb-ad1c-4d84-8d16-df5a8dd6876f",
  "https://dashboard.blooket.com/set/654580241cdf9a12fcc3a091",
  "https://dashboard.blooket.com/set/650e0c4410b9ba39a25071d3",
  "https://dashboard.blooket.com/set/650e0ce04449dfbdc5bbbc3f",
  "https://dashboard.blooket.com/set/654581b31cdf9a12fcc3a111",
  "https://create.kahoot.it/details/5e83a10b-6424-4720-8c11-ce29d1841b50",
  "https://dashboard.blooket.com/set/65580057eab4cf867bac5039",
  "https://dashboard.blooket.com/set/655803e2129e416ec368e5d9",
  "https://dashboard.blooket.com/set/6558049a129e416ec368e625",
  "https://create.kahoot.it/share/vidukathai-1-nataniya-n3a/656e056a-4e51-4151-b484-7ed1bea6f819",
  "https://create.kahoot.it/share/vidukathai-2-nataniya-n3a/4ee0d5c3-b559-4dac-aa4e-db2fcedaef1b",
  "https://create.kahoot.it/details/8fcf9681-e888-4400-87e4-47aa97e0ef29",
  "https://create.kahoot.it/details/d41b0017-6a08-46dc-8780-341cfa2c0366",
  "https://create.kahoot.it/share/school-related-vocab/b22388d7-a72e-4134-b68c-6deae581e411",
  "https://create.kahoot.it/share/fruits-related-vocab/0f55c559-803e-4c0b-a965-221b9e268328",
  "https://create.kahoot.it/details/40f207fe-a40c-4219-ab68-af7cffbef11d",
  "https://create.kahoot.it/details/a92b9556-0f16-4332-a401-8457aba5e4f1",
  "https://dashboard.blooket.com/set/64dbf3e8a88a121ebbf318dd",
  "https://dashboard.blooket.com/set/64ce9a60a88a121ebbf0f16b",
  "https://dashboard.blooket.com/set/64e9581698ebc0f6aa18d615",
  "https://dashboard.blooket.com/set/6500ee0a244ad78d55ee457b",
  "https://dashboard.blooket.com/set/6508f068e7972511568ec888",
  "https://dashboard.blooket.com/set/652eebc54ec2e6fb3d17ac0e",
  "https://create.kahoot.it/details/70a0078a-4e44-48fb-b575-c06c8b1e4fed",
  "https://create.kahoot.it/details/bdc5e5fd-e233-43f9-8852-19804f2a1554",
  "https://create.kahoot.it/details/3fa14ebb-7376-400f-99cd-b7489e6baf6a",
  "https://create.kahoot.it/details/cb66dd61-cb8e-4e96-bf9e-8c3f8dd42995",
  "https://create.kahoot.it/details/f36c8da8-28d7-410d-9ab0-0e69a7eb6454",
  "https://create.kahoot.it/details/9f441b56-f837-4c81-9f66-1de6aaaf5e50",
  "https://create.kahoot.it/details/33dbb082-1cc5-4a50-881a-c3efdbceea1f",
  "https://create.kahoot.it/details/3122c6b1-9283-458b-be63-ca74a2f41781",
  "https://create.kahoot.it/share/nillai-1c-chapter-11/0a8ac546-6908-4c6c-a521-70dddae74314",
  "https://create.kahoot.it/share/nillai-1c-chapter-12-quiz/e5e6b8d9-1e3c-4b94-a412-5b90a09d5b29",
  "https://create.kahoot.it/share/nillai-1c-chapter-13-quiz/983d9f33-4bcc-4cbe-bde7-27619db3da91",
  "https://dashboard.blooket.com/set/656cf8fd9f0599e5f42755b5",
  "https://create.kahoot.it/details/d3c5633e-db8d-4680-ab16-ff6c673490be",
  "https://create.kahoot.it/details/819f39eb-100d-4159-89f1-c7fe4cacc2b8",
  "https://create.kahoot.it/details/7ca0c2aa-6a78-4d0e-94e1-3e66117418dc",
  "https://create.kahoot.it/details/3d3529a4-c482-4aef-b397-ba839c975815",
  "https://dashboard.blooket.com/set/65a15ee43b04c9c7d738ffb5",
  "https://create.kahoot.it/share/nillai-1c-chapter-16-kahoot/a6341c8d-1916-406e-83e5-8524b8618601",
  "https://dashboard.blooket.com/set/65aa84c1d12fe47d2b4f4e1f",
  "https://dashboard.blooket.com/set/65c58e3a4cb1c7ac671a717a",
  "https://dashboard.blooket.com/set/65c59495102d673985621a05",
  "https://dashboard.blooket.com/set/64f219ce873b20af0ea10808",
  "https://dashboard.blooket.com/set/64dec843a88a121ebbf3f585",
  "https://dashboard.blooket.com/set/64e92454bbd9076b817b9074",
  "https://dashboard.blooket.com/set/65d402e10228d7e9d1dde7d0",
  "https://create.kahoot.it/share/nillai-1c-chapter-18-kahoot/9e65b7e4-dbd9-4b86-b23c-32199d6b0a80",
  "https://create.kahoot.it/share/nilai-1a-set-3/6fdc9b83-ed4a-4b12-9846-52d299d9a3df",
  "https://create.kahoot.it/share/nilai1a/d9454201-6302-4124-b8f1-d2e656f1f146",
  "https://dashboard.blooket.com/set/65a1d2a63a2ecc2c66e70204",
  "https://dashboard.blooket.com/set/65ab0ebc6c3a94c6a5bc815e",
  "https://dashboard.blooket.com/set/65a1dd29a0bf678e64d0d78d",
  "https://dashboard.blooket.com/set/64e93066bbd9076b817b9642",
  "https://dashboard.blooket.com/set/64e9334298ebc0f6aa18c7d3",
  "https://dashboard.blooket.com/set/655804a20908f41030ee86b6",
  "https://dashboard.blooket.com/set/65cce027362c177b30236426",
  "https://dashboard.blooket.com/set/65ccc6f0385910f368896107",
  "https://dashboard.blooket.com/set/65d8c10896344ad1f5851528",
  "https://create.kahoot.it/share/nillai-1c-chapter-19-kahoot/b64a72a3-c05a-4236-ab23-90b384cc6099"
];

export default links;